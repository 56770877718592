import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/regions/header"
import Container from "../components/block/container"
import FieldText from "../components/field/fieldText"
import ImageTextPositioned from "../components/item/imageTextPositioned"
import BlockTry from "../components/full/blockTry"
import DecoratedImage from "../components/item/decoratedImage"
import PrivateNotesStrip from "../assets/images/twisted-strip--create-private-notes.png"
import GetYourRequestsStrip from "../assets/images/twisted-strip--get-your-requests-in-order.png"
import "../styles/components/block--page-top-content--features.scss"
import "../styles/components/block--possibilities.scss"

const FeaturesPage = () => (
  <>
    <Header />
    <Layout>
      <Seo
        title="Features"
        description="Dive into all the possibilities of Sideline."
        pageClass="page--features"
      />

      <Container classes="block--page-top-content block--page-top-content--features">
        <FieldText>
          <h3 className="text text--centered text--red text--uppercase">
            Features
          </h3>
          <h1 className="text text--centered">
            Dive into all the possibilities of Sideline
          </h1>
        </FieldText>
      </Container>

      <Container classes="block--possibilities">
        <ImageTextPositioned
          position="left"
          classes="image-text--private-notes"
        >
          <DecoratedImage
            stripClasses={"private-notes-strip"}
            stripSrc={PrivateNotesStrip}
            stripStyle={{ top: "-115px", left: "-73px" }}
          >
            <StaticImage
              as="figure"
              className="field field--image"
              src="../assets/images/create-private-notes.png"
              quality="90"
              placeholder="blurred"
              alt="Create Private Notes"
            />
          </DecoratedImage>
          <FieldText>
            <h3>Create Private Notes</h3>
            <h4>Lorem Dolor ipsum conseqtetur</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.A animi
              blanditiis consequuntur, debitis delectus dolorum exercitationem
              fuga magnam perferendis praesentium provident reiciendis
              temporibus unde? A ab architecto aut consequuntur, officiis omnis
              praesentium quis ratione repellendus voluptates?
            </p>
          </FieldText>
        </ImageTextPositioned>

        <ImageTextPositioned
          position="right"
          classes="image-text--track-requests"
        >
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/track-your-requests.png"
            quality="90"
            placeholder="blurred"
            alt="Track Your Requests"
          />
          <FieldText>
            <h3>Track Your Requests</h3>
            <h4>Lorem Dolor ipsum conseqtetur</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Accusamus deserunt explicabo illum, laboriosam laudantium magni
              nemo nesciunt, obcaecati quaerat quisquam similique, sint totam
              ullam veniam!
            </p>
          </FieldText>
        </ImageTextPositioned>

        <ImageTextPositioned
          position="left"
          classes="image-text--requests-in-order"
        >
          <DecoratedImage
            stripClasses={"get-your-requests-strip"}
            stripSrc={GetYourRequestsStrip}
          >
            <StaticImage
              as="figure"
              className="field field--image"
              src="../assets/images/get-your-requests-in-order.png"
              quality="90"
              placeholder="blurred"
              alt="Get Your Requests in Order"
            />
          </DecoratedImage>
          <FieldText>
            <h3>Get Your Requests in Order</h3>
            <h4>Lorem Dolor ipsum conseqtetur</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet,
              laborum, sequi? At ipsum modi neque nisi nobis pariatur quam
              quisquam, repellendus, sequi suscipit vitae voluptate?
            </p>
          </FieldText>
        </ImageTextPositioned>

        <ImageTextPositioned
          position="right"
          classes="image-text--detailed-reports"
        >
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/amazing-detailed-reports.png"
            quality="90"
            placeholder="blurred"
            alt="Amazing Detailed Reports"
          />
          <FieldText>
            <h3>Amazing Detailed Reports</h3>
            <h4>Lorem Dolor ipsum conseqtetur</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. A
              consectetur culpa et nulla, odio officia officiis quam quas quia
              quod sit.
            </p>
          </FieldText>
        </ImageTextPositioned>
      </Container>

      <BlockTry />
    </Layout>
  </>
)

export default FeaturesPage
